<template>
  <main class="main ">
    <header class="section_page">    
        <h1>Visualizing Social Interactions at an Art Festival</h1>
        <p class="text-mid-s">Central Pennsylvania Arts Fest App, Research Internship Project</p>
        <section class="project-overview">
          <p>There is, at present, a widespread adoption of social media and smartphones for interacting with trending events as well as sharing and connecting with friends. This produces a wealth of data which, with help of available technology, opens up opportunities to enhance community life.</p>
          <p>This project was an attempt to use data from such kind of data at a local arts festival to understand user's social interaction patterns.</p>
        </section>
        <div class="banner" alt="project thumbnail">
          <img src="@/assets/images/projects/cpfa/banner.jpg" />
        </div>
    </header>
    <div class="section_page">
      <section class="project-section" id="cpfa-research">
          <header>
            <h2>Research Goals</h2>
            <p class="text-mid">
              Understanding Social Media & Community Interactions
            </p>
          </header>
          <div class="project-text">
            <p>
              Mobile technology offers a new way to study impacts and consequences of information and communications technology for enhancing community activities, experiences in local communities. This project was a part of ongoing initiatives in community informatics to understand how data can be produced, gathered, analyzed, interpreted and used for community engagement.
            </p>
          </div>
        </section>
      <section class="project-section " id="cpfa-overview">
        <header>
          <h2>Project Overview & Context</h2>
          <p class="text-mid">
            Central Pennsylvania Arts Festival
          </p>
        </header>
        <div class="project-text">
          <p>
            Center for Human-Computer Interaction in Pennsylvania State University's Institute of Science and Technology (IST) has been working with the Central Pennsylvania Festival of Arts (CPFA) to enhance the community engagement.
          </p>
          <p>The CPFA is a popular annual event held every July. It attracts more than 125,000 visitors.
          </p>
          <p>For the event we designed, developed and deployed a mobile app and a web portal for the festival. for some time to develop a mobile application. Earlier version of the app was created for the previous year with the core features such as a schedule and program of events and profiles of the visiting artists and performers.</p>
          <p>This year (2015), it was <a rel="noopener noreferrer" href="https://news.psu.edu/story/362339/2015/07/06/science-and-technology/new-version-arts-festival-app-enables-users-create">redesigned </a> to allow users design their own experiences within the festival. It also had more personalized through location and activity data. The data generated through the app was used to generate and visualize networking among the users, and conduct our research surveys.</p>
          <p>The research objective was to study the use and impact of computing and communications infrastructures on community networks.</p>
        </div>
      </section>
      <section class="project-section " id="cpfa-goals">
        <header>
          <h2>Project Goals</h2>
          <p class="text-mid">Dial up the participation</p>
        </header>
        <div class="project-text">
          <p>
            Our approach to facilitate better community engagement was through more triggers on the app. The few features designed for that were:
          </p>
          <ul>
            <li>Location and activity based prompts through the app </li>
            <li>A 'meetup' type feature to allow attendees to create their own activities (games, cooking classes, yoga sessions, etc.) and invite/allow others to join. The meetup gives attendees a chance to create a part of their experience and thus, be more involved and engaged with the festival and other festival-goers. </li>
            <li>A new “wave” feature. A wave is similar to a "poke" on Facebook and is intended as a way of getting someone’s attention or an easy way to start conversations. </li>
          </ul>
          <img src="@/assets/images/projects/cpfa/app.jpg" />
        </div>
      </section>
      <section class="project-section " id="cpfa-role">
        <header>
          <h2>My role</h2>
          <p class="text-mid">Contextual Notification: Design Strategy</p>
        </header>
        <div class="project-text">
          <p>
            I joined mid-way through the project, and before the festival, I worked on the design of alerts and notifications for the app.
          </p>
          <p>More features means more push notifications. Apart from the event notifications, the app would push survey questions from time-to-time. As the questions were time and location sensitive, the requirement was to maximize prompt response.</p>
          <p>Due to limitation of time, I chose review-based research as my methodology to find the best times and contexts to push the questioning, without it negatively affecting the user experience.</p>
          <img src="@/assets/images/projects/cpfa/impact_model.png" />
        </div>
      </section>
      <section class="project-section " id="cpfa-viz">
        <header>
          <h2>Analysis</h2>
          <p class="text-mid">Data Visualization: Social Networks</p>
        </header>
        <div class="project-text">
          <p>
            Once the festival was over, the data gathered through the app about the users (likes, waves, comments) could be analyzed to determine the success of the redesign.
          </p>
          <p>The aim of the analysis was to get an overview of the interactions, and then zoom into individual networks for further insights. </p>
          <p>The best way to do that would be through a social graph. I used d3.js to create a force-directed clustered network graph.</p>
          <p>The festival network is displayed as a node-link graph, with a center node representing the social network of a user being examined, and secondary nodes representing anyone primary user interacted with during the festival. Lines/Edges represent links (likes/comments and waves)         between the primary user and others, while various line thickness is used to easily identify the degree of interaction between users.</p>
          <p>We shared the visualizations with the users - both their own (ego) network and the overall festival (whole) interactions. (Users could access their own data through an website, where they could log in with their app credentials)</p>
          <p>They were also given a few prompts to react to the visualizations, which could add to our insights in the network analysis, and characterize people's social practices in community events.</p>
          <img src="@/assets/images/projects/cpfa/website.jpg" />
        </div>
      </section>
      <section class="project-section " id="cpfa-learnings">
        <header>
          <h2>Key Learnings</h2>
          <p class="text-mid">Qualitative & Quantitative Analysis, Data Visualization</p>
        </header>
        <div class="project-text">
          <ul>
            <li>Being involved in many aspects of the project exposed me to several HCI avenues: social computing, empirical evaluation, social behavior science, interaction space design, handling quantitative data</li>
            <li>Working with data taught me about handling data and how to analyze them. To create the visualization, I also taught myself the fundamentals of data visualization as well as d3.js</li>
            <li>Working with a diverse team of researchers, developers and fellow interns from other student exchange programs taught me how to collaborate</li>
          </ul>
        </div>
      </section>
    </div>
  </main>
</template>

<script>
export default {

}
</script>

<style>

</style>